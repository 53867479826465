<template>
  <vs-card>
    <vs-tabs>
      <vs-tab label="Homepage">
        <settings-form slug="homepage" />
      </vs-tab>
      <vs-tab label="Navigation">
        <settings-form slug="navigation" />
      </vs-tab>
      <vs-tab label="Promotions">
        <settings-form slug="promotions" />
      </vs-tab>
      <vs-tab label="Fresh Life">
        <settings-form slug="fresh-life" />
      </vs-tab>
    </vs-tabs>
  </vs-card>
</template>

<script>
export default {
  components: {
    settingsForm: () =>
      import("../../../components/jsonSettings/settingsForm.vue"),
  },
};
</script>

<style lang="scss">
.el-textarea__inner:focus {
  border-color: black !important;
}
</style>
